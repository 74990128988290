<template>
<div  >
    <v-app-bar elevate-on-scroll flat outlined height="80px" class="pr-4" color="white"  >
        <v-toolbar-title class="pl-4 pt-12 title secondary-text font-weight-bold" >

          <div v-if="icon=='mdi'"><v-icon  large color="primary">{{titleIcon}}</v-icon> {{title }}</div>
          <div v-else><icon large color="primary" :id="titleIcon"></icon> {{title }}</div>
        </v-toolbar-title>
          <v-spacer></v-spacer>
    <v-toolbar-items >
      <!-- style="background: linear-gradient(to left, rgba(255, 205, 147), rgba(64, 157, 149, 0))" -->
        <v-tabs  right color="secondary " class="pt-4"  >
          <template v-for="(item,index) in menuItems" >
          <v-tab :key="item.title" class="font-weight-bold" ripple :to="{ name: item.routeName, params: item.routeParams }">{{item.title}}<v-icon v-if="item.icon !== undefined" :key="item.routeName">{{item.icon}}</v-icon></v-tab>
          <svg v-if="index != menuItems.length - 1" :key="'k'+item.title" width="15" class="ml-3 ">
            <line x1="15" y1="0" x2="0" y2="50" style="stroke:rgb(159,29,76);stroke-width:2" />
          </svg>

          </template>
        </v-tabs>
    </v-toolbar-items>
    </v-app-bar>

</div>
</template>

<script>
  export default {
    name: 'NavBar',
    props: {
      icon: {
        type: String,
        default: 'mdi'
      },
      title: String(),
      titleIcon: String(),
      menuItems:[]
    }
  }
</script>
<style>
.v-toolbar__content{
    /* border-bottom: 8px solid; */
    border-bottom-width: 1px;
    border-color: rgb(255, 205, 147);
}
</style>